import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

//import images
import avatar from "../../../assets/images/users/avatar-0-generated.png"

import withRouter from "components/Common/withRouter"
import AboutUs from "./AboutUs"
import Sidebar from "./Sidebar"

import { getApplyJob } from "store/actions"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

const CandidateOverview = props => {
  document.title = `${props?.router?.params?.userName} - Public Profile | Skill Mentors | Learn Earn Build`
  const params = props.router.params

  // const [showMessageArea, setShowMessageArea] = useState(true)

  const dispatch = useDispatch()

  const selectContactsState = state => state.contacts
  const ContactsProperties = createSelector(selectContactsState, Contacts => ({
    model: Contacts.user,
  }))

  const { model } = useSelector(ContactsProperties)

  const selectUserState = state => state.JobReducer
  const UserProperties = createSelector(selectUserState, jobReducer => ({
    user: jobReducer.jobApply,
    loading: jobReducer.loading,
  }))

  const { user, loading } = useSelector(UserProperties)
  const navigate = useNavigate();

  useEffect(() => {
    if (params && params.userName) {
      dispatch(getApplyJob({ userName: params.userName, modelId: model?._id }))
    }
  }, [params.userName])

  useEffect(() => {
  }, [user])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card className="mx-n4 mt-n4 bg-dark-subtle">
                <CardBody>
                  <div className="text-center mb-4">
                    <img
                      src={user?.image || avatar}
                      alt=""
                      className="avatar-md rounded-circle mx-auto d-block"
                    />
                    {user?.model?.name ?
                      <h5 className="mt-3 mb-1">
                        {user?.model?.name}
                      </h5>
                      : user?.firstName ?
                        <h5 className="mt-3 mb-1">
                          {user?.firstName} {user?.lastName}
                        </h5> :
                        <h5 className="mt-3 mb-1">
                          {user?.userName}
                        </h5>
                    }
                  </div>
                  {user?.model?._id && (
                    <div className="text-center">
                      <div>
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => {
                            if (user?.role === "Admin") { navigate("/course-grid") } else {
                              window.open("about:blank", "_self");
                              window.close();
                            }
                          }}
                        >
                          Exit Course{" "}
                          <i className="bx bx-exit align-baseline ms-1"></i>
                        </button>
                      </div>
                    </div>
                  )}
                  {!user?.model?._id && (
                    <div className="text-center">
                      <div>
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => {
                            window.open("about:blank", "_self");
                            window.close();
                          }}
                        >
                          Close Profile{" "}
                          <i className="bx bx-exit align-baseline ms-1"></i>
                        </button>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Sidebar user={user} />
            <AboutUs user={user} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(CandidateOverview)
