import React, { useEffect, useState } from "react"

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Form,
  Label,
  Input,
  FormFeedback,
  Button,
  Alert,
  Spinner,
} from "reactstrap"
import classnames from "classnames"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import * as Yup from "yup"
import { useFormik } from "formik"

import Dropzone from "react-dropzone"

import { useDispatch, useSelector } from "react-redux"
import { createSelector } from "reselect"

import { addNewUser, uploadFiles, deleteFileOnServer } from "../../store/contacts/actions"

const PagesFaqs = () => {
  //meta title
  document.title = "Customize Model | Skill Mentors | Learn Earn Build"

  const [activeTab, setActiveTab] = useState("1")
  const [selectedFiles, setSelectedFiles] = useState([])
  const [show, setShow] = useState(false)

  const dispatch = useDispatch()

  const selectContactsState = state => state.contacts
  const ContactsProperties = createSelector(selectContactsState, Contacts => ({
    user: Contacts.user,
    loading: Contacts.loading,
  }))

  const { user, loading } = useSelector(ContactsProperties)

  useEffect(() => {
    if (!loading) {
      if (user?.files?.length > 0) {
        let files = []
        for (let file of user?.files) {
          if (!selectedFiles?.find(f => f?.name === file?.fileName)) {
            files.push({
              name: file?.fileName,
              formattedSize: formatBytes(file?.size),
            })
          }
        }

        const prevFiles = selectedFiles?.filter(f => !f?.deleted);
        if (prevFiles?.length > 0) {
          files = [...prevFiles, ...files]
        }

        files = files.map(file => { file.recent = false; return file; });
        setSelectedFiles(files)
      }
    }
  }, [loading, user])

  function handleAcceptedFiles(files) {
    let totalFiles = selectedFiles?.length + files?.length
    if (totalFiles > 5) {
      setShow(true)
      setTimeout(() => {
        setShow(false)
      }, 5000)
    } else {
      const formData = new FormData()
      files = files.map(file => {
        file.fileformattedSize = formatBytes(file?.size);
        file.recent = true;

        formData.append("files", file);
        return file;
      })

      formData.append("modelName", user?.name)
      dispatch(uploadFiles(formData))
      setSelectedFiles([...selectedFiles, ...files])
    }
  }

  function deleteFile(file) {
    let totalFiles = selectedFiles?.length - 1
    if (totalFiles < 1) {
      setShow(true)
      setTimeout(() => {
        setShow(false)
      }, 5000)
    } else {
      let uploaded = [...selectedFiles]
      let index = uploaded.indexOf(file)
      if (index !== -1) {
        uploaded[index].recent = true;
        uploaded[index].deleted = true;
        setSelectedFiles(uploaded);
        dispatch(deleteFileOnServer({
          modelId: user?._id,
          fileName: user?.files?.find(f => f.fileName === uploaded[index]?.name)?.fileName
        }));
      }
    }
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      name: user?.name || "",
      openAIKey: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Model Name"),
      openAIKey: user?._id
        ? Yup.string()
        : Yup.string().required("Please Enter Your Open AI Key"),
    }),
    onSubmit: async (values, actions) => {
      dispatch(addNewUser({ ...values, ...{ _id: user?._id } }))
      actions.resetForm({
        values: {
          openAIKey: "",
        },
      })
    },
  })

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Skill Mentors" breadcrumbItem="Customize Model" />

          <div className="checkout-tabs">
            <Row>
              <Col lg="2">
                <Nav className="flex-column" pills>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        setActiveTab("1")
                      }}
                      style={{
                        backgroundColor:
                          activeTab === "1" ? "#fbebd3" : "#ffffff",
                      }}
                    >
                      <i className="fas fa-cog d-block check-nav-icon mt-4 mb-2" />
                      <p className="font-weight-bold mb-4">Model Config</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        if (user?.name) {
                          setActiveTab("2")
                        }
                      }}
                      style={{
                        backgroundColor:
                          activeTab === "2" ? "#fbebd3" : "#ffffff",
                      }}
                    >
                      <i className="bx bx-file d-block check-nav-icon mt-4 mb-2" />
                      <p className="font-weight-bold mb-4">Model Files</p>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col lg="10">
                <Card>
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <div className="form-group mb-3">
                            <Label className="form-label" for="name">
                              Model Name
                            </Label>
                            <Input
                              id="name"
                              name="name"
                              autoComplete="off"
                              className="form-control"
                              placeholder="Enter Model Name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.name || ""}
                              invalid={
                                validation.touched.name &&
                                  validation.errors.name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.name &&
                              validation.errors.name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.name}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="form-group mb-3">
                            <Label className="form-label" for="openAIKey">
                              Open AI Key
                            </Label>
                            <Input
                              id="openAIKey"
                              name="openAIKey"
                              autoComplete="off"
                              className="form-control"
                              placeholder="Enter Open AI Key"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.openAIKey || ""}
                              invalid={
                                validation.touched.openAIKey &&
                                  validation.errors.openAIKey
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.openAIKey &&
                              validation.errors.openAIKey ? (
                              <FormFeedback type="invalid">
                                {validation.errors.openAIKey}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="text-end">
                            <Button
                              type="submit"
                              className="me-3"
                              color="success"
                            >
                              {loading && (
                                <i
                                  className="bx bx-loader-circle bx-spin me-2"
                                  style={{ fontSize: "medium", color: "white" }}
                                />
                              )}
                              Save Model
                            </Button>
                          </div>
                        </Form>
                      </TabPane>
                      <TabPane tabId="2">
                        <Alert
                          color="warning"
                          isOpen={show}
                          toggle={() => {
                            setShow(false)
                          }}
                        >
                          Please upload upto 5 files max of 1 GB each.
                        </Alert>
                        <Form>
                          <Dropzone
                            accept={{ "application/pdf": [".pdf"] }}
                            maxFiles={5}
                            maxSize={1073741824}
                            multiple={true}
                            onDrop={handleAcceptedFiles}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone">
                                <div
                                  className="dz-message needsclick mt-2"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>Drop files here or click to upload.</h4>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {selectedFiles?.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        {!f.recent ? (
                                          <Button
                                            color="link"
                                            type="button"
                                            style={{ padding: "0px" }}
                                            id={`${user.name}-edit`}
                                            onClick={() => {
                                              deleteFile(f)
                                            }}
                                          >
                                            <i
                                              className="bx bx-trash-alt"
                                              style={{
                                                fontSize: "x-large",
                                                color: "black",
                                              }}
                                            />
                                          </Button>
                                        ) : (
                                          <Spinner
                                            className="ms-2"
                                            color="warning"
                                          />
                                        )}
                                      </Col>
                                      <Col>
                                        <p className="text-muted font-weight-bold mb-0">
                                          <strong>{f.name}</strong>
                                        </p>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              )
                            })}
                          </div>
                        </Form>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PagesFaqs
