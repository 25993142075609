import moment from "moment"
import React from "react"
import { Card, CardBody, Col, Row, Button } from "reactstrap"

const Sidebar = props => {
  return (
    <React.Fragment>
      <Col lg={3}>
        <Card>
          <CardBody>
            {props?.user?.model?._id ? <h5 className="mb-3">Course Author</h5> : null}

            <ul className="list-unstyled vstack gap-3 mb-0">
              <li>
                <div className="d-flex">
                  <i className="bx bx-user font-size-18 text-warning"></i>

                  <div className="ms-3">
                    <h6 className="mb-1 fw-semibold">Name:</h6>
                    <span className="text-muted">{props?.user?.firstName} {props?.user?.lastName}</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <i className="bx bx-card font-size-18 text-warning"></i>

                  <div className="ms-3">
                    <h6 className="mb-1 fw-semibold">Designation:</h6>
                    <span className="text-muted">{props?.user?.description}</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <i className="bx bx-mail-send font-size-18 text-warning"></i>

                  <div className="ms-3">
                    <h6 className="mb-1 fw-semibold">Email:</h6>
                    <span className="text-muted">{props?.user?.email}</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <i className="bx bx-phone-call font-size-18 text-warning"></i>
                  <div className="ms-3">
                    <h6 className="mb-1 fw-semibold">Phone:</h6>
                    <span className="text-muted">{props?.user?.phoneNo}</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <i className="bx bx-link font-size-18 text-warning"></i>
                  <div className="ms-3">
                    <h6 className="mb-1 fw-semibold">Website:</h6>
                    <span className="text-muted">{props?.user?.website}</span>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <i className="bx bx-calendar font-size-18 text-warning"></i>
                  <div className="ms-3">
                    <h6 className="mb-1 fw-semibold">User Since:</h6>
                    <span className="text-muted">
                      {moment(props?.user?.createdAt).format("MMMM Do YYYY")}
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </CardBody>
        </Card>
        {props?.user?.whatsapp || props?.user?.facebook || props?.user?.instagram || props?.user?.linkedIn ?
          <Card>
            <CardBody>
              <h5 className="mb-3">Social Contacts</h5>

              <ul className="list-unstyled vstack gap-3 mb-0">
                {props?.user?.whatsapp && (
                  <li>
                    <div
                      className="d-flex"
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <i className="bx bxl-whatsapp font-size-18 text-warning"></i>

                      <div className="ms-3">
                        <h6 className="mb-1 fw-semibold">WhatsApp:</h6>
                        <span
                          className="text-muted"
                          style={{ textOverflow: "hidden" }}
                        >
                          {props?.user?.whatsapp}
                        </span>
                      </div>
                    </div>
                  </li>
                )}
                {props?.user?.facebook && (
                  <li>
                    <div
                      className="d-flex"
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <i className="bx bxl-facebook font-size-18 text-warning"></i>

                      <div className="ms-3">
                        <h6 className="mb-1 fw-semibold">Facebook:</h6>
                        <span className="text-muted">
                          {props?.user?.facebook}
                        </span>
                      </div>
                    </div>
                  </li>
                )}
                {props?.user?.instagram && (
                  <li>
                    <div
                      className="d-flex"
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <i className="bx bxl-instagram font-size-18 text-warning"></i>

                      <div className="ms-3">
                        <h6 className="mb-1 fw-semibold">Instagram:</h6>
                        <span className="text-muted">
                          {props?.user?.instagram}
                        </span>
                      </div>
                    </div>
                  </li>
                )}
                {props?.user?.linkedIn && (
                  <li>
                    <div
                      className="d-flex"
                      style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <i className="bx bxl-linkedin font-size-18 text-warning"></i>

                      <div className="ms-3">
                        <h6 className="mb-1 fw-semibold">LinkedIn:</h6>
                        <span className="text-muted">
                          {props?.user?.linkedIn}
                        </span>
                      </div>
                    </div>
                  </li>
                )}
              </ul>
            </CardBody>
          </Card>
          : null
        }
      </Col>
    </React.Fragment>
  )
}

export default Sidebar
