import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Row,
  Spinner,
  UncontrolledAlert,
  UncontrolledTooltip,
} from "reactstrap"
import { map } from "lodash"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Card
import CardContact from "./card-contact"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"

import {
  getUsers as onGetUsers,
  resetMessages,
  resetUser,
} from "../../store/contacts/actions"

const ContactsGrid = () => {
  //meta title
  document.title = "Course Models | Skill Mentors | Learn Earn Build"

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [show, setShow] = useState(false)

  const selectContactsState = state => state.contacts
  const ContactsProperties = createSelector(selectContactsState, Contacts => ({
    users: Contacts.users,
    loading: Contacts.loading,
    error: Contacts.error,
    response: Contacts.response,
  }))

  const { users, loading, error, response } = useSelector(ContactsProperties)

  const loggedIn = JSON.parse(localStorage.getItem("authUser"))

  useEffect(() => {
    dispatch(onGetUsers())
  }, [dispatch])

  useEffect(() => {
    if (response?.message || error?.message) {
      setShow(true)
      setTimeout(() => {
        dispatch(resetMessages())
        setShow(false)
      }, 10000)
    }
  }, [response, error])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Skill Mentors" breadcrumbItem="Course Models" />

          <UncontrolledAlert
            color={error?.message ? "danger" : "info"}
            className="alert-dismissible fade show mb-0"
            role="alert"
            isOpen={show}
            toggle={() => {
              setShow(false)
            }}
          >
            {error?.message ? <i className="mdi mdi-block-helper"></i> : response?.message ? <i className="mdi mdi-alert-circle-outline"></i> : null}{" "}
            {error?.message || response?.message}
          </UncontrolledAlert>

          {loading ? (
            <Spinner
              type="grow"
              className="ms-n3"
              style={{ position: "relative", left: "50%" }}
              color="secondary"
            />
          ) : (
            <>
              <Row>
                {map(users, user => (
                  <CardContact
                    user={user}
                    cardKey={"_user_" + user?._id}
                    element={"model"}
                  />
                ))}

                <Col xl="3" sm="6">
                  <Card className="text-center">
                    <CardBody>
                      <div className="avatar-sm mx-auto mb-4">
                        <span
                          className={
                            "avatar-title rounded-circle bg-primary-subtle" +
                            " font-size-16"
                          }
                        >
                          {"+"}
                        </span>
                      </div>
                      <h5 className="font-size-15 mb-2 text-dark">
                        Create New Model
                      </h5>
                      <p className="text-muted mb-2">
                        Total Models: {users?.length}
                      </p>
                    </CardBody>
                    <CardFooter className="bg-transparent border-top">
                      <div className="contact-links font-size-20">
                        <div className="d-sm-flex align-items-center justify-content-center">
                          <Button
                            color="link"
                            style={{ padding: "0px" }}
                            disabled={
                              loggedIn?.role !== "Admin" && users?.length > 0
                            }
                            type="button"
                            id={`create-new`}
                            onClick={() => {
                              dispatch(resetUser())
                              navigate("/customize-model")
                            }}
                          >
                            <i
                              className="bx bx-plus"
                              style={{ fontSize: "large", color: "black" }}
                              id="addnewmodel"
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target="addnewmodel"
                            >
                              Create Model
                            </UncontrolledTooltip>
                          </Button>
                        </div>
                      </div>
                    </CardFooter>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <div className="text-center my-3">
                    <Button
                      className="text-warning"
                      color="link"
                      type="button"
                      onClick={() => {
                        dispatch(onGetUsers())
                      }}
                    >
                      <i className="bx bx-rotate-right me-2" />
                      Refresh
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ContactsGrid)
