import Spinners from "components/Common/Spinner"
import EmojiPicker from "emoji-picker-react"
import React, { useRef, useState, useEffect } from "react"
import { createSelector } from "reselect"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledAlert,
  UncontrolledTooltip,
  UncontrolledDropdown,
} from "reactstrap"
import SimpleBar from "simplebar-react"
import moment from "moment"

import { processQuery } from "store/actions"
import avatar from "../../../assets/images/users/avatar-0-generated.png"

const AboutUs = props => {
  const [messages, setMessages] = useState([])
  const [messagesData, setMessagesData] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [copyMsgAlert, setCopyMsgAlert] = useState(false)
  const [emoji, setEmoji] = useState(false)
  const [emojiArray, setemojiArray] = useState("")
  const [curMessage, setCurMessage] = useState("")
  const [isdisable, setDisable] = useState(false)
  const [currentUser, setCurrentUser] = useState(props?.user)
  const [alertText, setAlertText] = useState("")

  const dispatch = useDispatch()

  const selectUserState = state => state.JobReducer
  const UserProperties = createSelector(selectUserState, jobReducer => ({
    messageData: jobReducer.messageData,
    loadMessage: jobReducer.loadMessage,
  }))

  const { messageData, loadMessage } = useSelector(UserProperties)

  const copyMsg = ele => {
    var copyText = ele
      .closest(".conversation-list")
      .querySelector("p").innerHTML
    navigator.clipboard.writeText(copyText)

    setAlertText("Message copied to clipboard")
    setCopyMsgAlert(true)
    if (copyText) {
      setTimeout(() => {
        setAlertText("")
        setCopyMsgAlert(false)
      }, 1000)
    }
  }

  const calculateDuration = updatedAt => {
    let duration = moment.duration(moment().diff(moment(updatedAt)))
    let days = ""
    if (duration.asYears() >= 1)
      days = Math.round(duration.asYears()) + " years"
    else if (duration.asMonths() >= 1)
      days = Math.round(duration.asMonths()) + " months"
    else if (duration.asWeeks() >= 1)
      days = Math.round(duration.asWeeks()) + " weeks"
    else if (duration.asDays() >= 1)
      days = Math.round(duration.asDays()) + " days"
    else if (duration.asHours() >= 1)
      days = Math.round(duration.asHours()) + " hours"
    else if (duration.asMinutes() >= 1)
      days = Math.round(duration.asMinutes()) + " minutes"
    else if (duration.asSeconds() >= 1)
      days = Math.round(duration.asSeconds()) + " seconds"

    if (days === "") days = "moments"
    return days
  }

  const addMessage = () => {
    if (curMessage?.trim() !== "") {
      const newMessage = {
        senderId: { userName: "Anonymous", firstName: "Anonymous" },
        text: curMessage?.trim(),
        isActive: true,
        updatedAt: moment(),
      }

      try {
        setMessagesData([...messagesData, newMessage])
        setDisable(false)
        setEmoji(false)
        setLoading(true)
        dispatch(processQuery(props?.user?.model?._id, curMessage?.trim()))
      } catch (error) {
        setDisable(true)
        setEmoji(false)
        setLoading(false)

        setAlertText(`Error: ${error}`)
        setCopyMsgAlert(true)
        if (copyText) {
          setTimeout(() => {
            setAlertText("")
            setCopyMsgAlert(false)
          }, 5000)
        }
      }
    }
  }

  useEffect(() => {
    const reply = {
      senderId: {
        userName: messageData?.userName,
        firstName: messageData?.firstName,
        lastName: messageData?.lastName,
      },
      text: messageData?.message?.content,
      isActive: true,
      updatedAt: moment(),
    }

    setDisable(true)
    setEmoji(false)
    setLoading(false)
    if (reply?.text) setMessagesData([...messagesData, ...[reply]])
  }, [messageData])

  const onEmojiClick = (event, emojiObject) => {
    setemojiArray([...emojiArray, emojiObject.emoji])
    setCurMessage(curMessage + event.emoji)
    setDisable(true)
  }

  const scroollRef = useRef(null)
  useEffect(() => {
    if (scroollRef.current) {
      scroollRef.current.getScrollElement().scrollTop =
        scroollRef.current.getScrollElement().scrollHeight
    }
  }, [messages])

  return (
    <React.Fragment>
      {props?.user?.model?._id ?
        <Col lg={9}>
          <div className="d-lg-flex">
            <div className="w-100 user-chat" style={{ height: "500px" }}>
              {props?.user?.firstName !== "" && (
                <Card>
                  <div className="p-4 border-bottom ">
                    <Row>
                      <Col md="8" xs="9">
                        <h5 className="font-size-15 mb-1">
                          {props?.user?.firstName} {props?.user?.lastName}
                        </h5>

                        <p className="text-muted mb-0">
                          <i
                            className={
                              "mdi mdi-circle text-success align-middle me-2"
                            }
                          />
                          {"Active now"}
                        </p>
                      </Col>
                    </Row>
                  </div>

                  <div>
                    <div className="chat-conversation p-3">
                      <SimpleBar ref={scroollRef} style={{ height: "280px" }}>
                        <ul className="list-unstyled mb-0">
                          {messagesData &&
                            (messagesData || []).map((message, index) => {
                              return (
                                <li
                                  key={index}
                                  className={
                                    message.senderId &&
                                      message.senderId.userName !==
                                      currentUser.userName
                                      ? ""
                                      : "right"
                                  }
                                >
                                  <div className="conversation-list">
                                    <UncontrolledDropdown>
                                      <DropdownToggle
                                        tag="a"
                                        className="dropdown-toggle"
                                      >
                                        <i className="bx bx-dots-vertical-rounded" />
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem
                                          onClick={e => copyMsg(e.target)}
                                        >
                                          Copy
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                    {message.senderId &&
                                      message.senderId.firstName && (
                                        <div className="ctext-wrap">
                                          <div className="conversation-name">
                                            {message.senderId &&
                                              message.senderId.userName ===
                                              currentUser.userName
                                              ? message.senderId.firstName
                                              : "You"}
                                          </div>
                                          <p>{message.text}</p>
                                          {/* {message.images && <img src={message.images} alt="" width="150px" />} */}
                                          {message.updatedAt && (
                                            <p className="chat-time mb-0">
                                              <i className="bx bx-time-five align-middle me-1"></i>
                                              {calculateDuration(
                                                message.updatedAt
                                              )}{" "}
                                              ago
                                            </p>
                                          )}
                                        </div>
                                      )}
                                  </div>
                                </li>
                              )
                            })}
                        </ul>
                        {isLoading ? (
                          <Spinners setLoading={setLoading} time={360000} />
                        ) : (
                          <></>
                        )}
                      </SimpleBar>
                    </div>

                    {copyMsgAlert && (
                      <UncontrolledAlert
                        color="warning"
                        dismissible
                        role="alert"
                      >
                        {alertText}
                      </UncontrolledAlert>
                    )}
                    {emoji && (
                      <EmojiPicker
                        onEmojiClick={onEmojiClick}
                        width={250}
                        height={382}
                      />
                    )}
                    <div className="p-3 chat-input-section">
                      <Row>
                        <Col>
                          <div className="position-relative">
                            <input
                              id="message"
                              type="text"
                              value={curMessage}
                              onChange={e => {
                                setDisable(true)
                                setCurMessage(e.target.value)
                              }}
                              className="form-control chat-input"
                              placeholder="Enter Message..."
                            />
                            <div className="chat-input-links">
                              <ul className="list-inline mb-0">
                                <li
                                  className="list-inline-item"
                                  onClick={() => setEmoji(!emoji)}
                                >
                                  <Link to="#">
                                    <i
                                      className="mdi mdi-emoticon-happy-outline me-1"
                                      id="Emojitooltip"
                                    />
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="Emojitooltip"
                                    >
                                      Emojis
                                    </UncontrolledTooltip>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Col>
                        <Col className="col-auto">
                          <Button
                            type="button"
                            color="secondary"
                            disabled={!isdisable}
                            onClick={() => addMessage()}
                            className="btn btn-secondary btn-rounded chat-send w-md "
                          >
                            <span className="d-none d-sm-inline-block me-2">
                              Send
                            </span>{" "}
                            <i className="mdi mdi-send" />
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card>
              )}
            </div>
          </div>
        </Col>
        : null
      }
    </React.Fragment>
  )
}

export default AboutUs
